<template>
  <div class="main">
    <div class="mobile header">
      <img src="@/assets/img/mobileMenu.webp" alt="menu">
      <img src="@/assets/img/mobileSignIn.webp" alt="in">
    </div>
    <div class="bg_dark"></div>
    <div class="modal">
      <div class="modal_left">
        <img src="@/assets/img/banner.webp" alt="banner" />
      </div>
      <div class="modal_right">
        <div class="close_block desc"></div>
        <div class="modal_contend">
          <h1 class="title">Регистрация</h1>
          <p class="sub-title mobile">Зарегистрируйтесь и начните выигрывать!</p>
          <form
            action="#"
            id="registrationForm"
            class="registrationForm"
            :class="{'form_disabled': isRequestSending}"
            @submit.prevent="submitForm"
          >
            <input
              required
              type="tel"
              id="phone"
              placeholder="Введите телефон"
              minlength="11"
              v-model="phone"
            />
            <input
              required
              type="password"
              id="password"
              placeholder="Введите пароль"
              v-model="password"
            />
            <input
              required
              type="text"
              id="promo"
              placeholder="Введите промокод"
              v-model="promocode"
            />
            <button class="btn_registr" id="registr" type="submit">
              <span class="desc">Зарегистрироваться</span>
              <span class="mobile">Регистрация</span>
            </button>
          </form>
          <div class="sign_in_Contend">
            <p>Есть аккаунт? <a href="#">Войти</a></p>
          </div>
        </div>
      </div>
    </div>
    <div class="rules_container">
      <p>Регистрируясь, вы соглашаетесь с <a href="#">правилами</a> казино</p>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      phone: '+7',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'OPA',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
